var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"small","spinning":_vm.spinnerLoader}},[_c('div',{staticClass:"card",class:_vm.$style.container},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"text-center mb-2"},[_c('img',{staticClass:"mr-2",attrs:{"src":"resources/images/logo.png","height":"150"}})]),_c('div',{staticClass:"text-dark font-size-24 text-center"},[_c('strong',[_vm._v("Kiosko de facturación")])]),_c('a-divider')],1)]),(!_vm.hasInvoice)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form',{attrs:{"form":_vm.verificationForm},on:{"submit":_vm.handleSubmitVerification}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"Número de ticket"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'id',
									{
										rules: [
											{
												required: true,
												message: 'Ingresa número de ticket',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'id',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa número de ticket',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],staticClass:"text-uppercase",attrs:{"disabled":_vm.validTicket,"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"Fecha y hora (DD/MM/AAAA HH:MM) am/pm"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['validator']),expression:"['validator']"},{name:"mask",rawName:"v-mask",value:('##/##/#### ##:## AA'),expression:"'##/##/#### ##:## AA'"}],staticClass:"text-lowercase",attrs:{"disabled":_vm.validTicket,"autocomplete":"off"}})],1)],1),(!_vm.validTicket)?_c('div',{staticClass:"col-12 text-right"},[_c('a-divider'),_c('button',{staticClass:"btn btn-info",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.resetForm($event, 'verification')}}},[_vm._v(" Limpiar ")]),_c('button',{staticClass:"ml5 btn btn-success",attrs:{"htmlType":"submit","loading":_vm.loading}},[_vm._v(" Validar ")])],1):_vm._e()])]),(_vm.validTicket)?_c('div',[_c('a-divider'),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"RFC"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'rfc',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu RFC',
												} ],
										} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'rfc',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu RFC',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],staticClass:"text-uppercase",attrs:{"autocomplete":"off"},on:{"change":_vm.onChangeRFC}})],1)],1),_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"Código Postal"}},[_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"},{name:"decorator",rawName:"v-decorator",value:([
										'codigo_postal',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu Código Postal',
												} ],
										} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'codigo_postal',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu Código Postal',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],staticClass:"text-uppercase",attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'email',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu Email',
												},
												{
													email: true,
													message: 'Ingresa email válido',
												} ],
										} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'email',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu Email',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\temail: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa email válido',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],staticClass:"text-lowercase",attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-12"},[_c('a-form-item',{attrs:{"label":"Régimen Fiscal"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'regimen_fiscal',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												} ],
										} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'regimen_fiscal',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"show-search":""}},_vm._l((_vm.taxRegimes),function(element,index){return _c('a-select-option',{key:index,attrs:{"value":element.id}},[_vm._v(" "+_vm._s(element.id)+" - "+_vm._s(element.description)+" ")])}),1)],1)],1),_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"Uso de CFDI"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'uso_cfdi',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												} ],
										} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'uso_cfdi',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],attrs:{"show-search":""}},_vm._l((_vm.cfdiUses),function(element,index){return _c('a-select-option',{key:index,attrs:{"value":element.id}},[_vm._v(" "+_vm._s(element.id)+" - "+_vm._s(element.description)+" ")])}),1)],1)],1),_c('div',{staticClass:"col-8"},[_c('a-form-item',{attrs:{"label":"Razón Social"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'razon_social',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												} ],
										} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'razon_social',\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t]"}],staticClass:"text-uppercase",attrs:{"autocomplete":"off"}})],1)],1)]),_c('a-divider'),_c('div',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-info",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.resetForm($event, 'billing')}}},[_vm._v(" Limpiar ")]),_c('button',{staticClass:"ml5 btn btn-success",attrs:{"htmlType":"submit","loading":_vm.loading}},[_vm._v(" Solicitar factura ")])])],1)],1):_vm._e()],1)]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center"},[_c('h4',{staticClass:"text-muted"},[_vm._v("Tu factura ha sido enviada por correo electrónico.")]),_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.downloadBill('xml')}}},[_vm._v(" Descargar XML ")]),_c('button',{staticClass:"ml5 btn btn-info",on:{"click":function($event){return _vm.downloadBill('pdf')}}},[_vm._v(" Descargar PDF ")])]),_c('div',{staticClass:"col-md-12 text-center",staticStyle:{"padding-top":"10px"}},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.initModule}},[_vm._v(" Generar nueva factura ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }